import ContentMargins from "../components/layout/ContentMargins";
import Layout from "../components/layout/layout";
import PageTitle from "../components/contentHeadings/PageTitle";
import React, {useEffect} from 'react';
import Seo from "../components/Seo";

const GavekortSuccessPage = () => {
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.gtag('event', 'giftcard_purchase');
        }
    }, []);

    return(
        <Layout>
            <Seo title={"Takk for ditt kjøp!"} meta={[{name: "robots", content: "noindex"}]}/>
            <ContentMargins>
                <PageTitle>Takk for ditt kjøp!</PageTitle>
                <p>En digital utgave av gavekortet blir sendt til din e-post, og en fysisk utgave blir sendt til deg i posten.</p>
            </ContentMargins>
        </Layout>
    )
};

export default GavekortSuccessPage;